import React from "react";
import { Link } from "gatsby";
import { Row, Col } from "react-bootstrap";
import img_404 from "../images/illustrations/illustration_404.svg";

import SEO from "../components/common/SEO";
// styles

const styles = {
  sectionA: {
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

// markup
const NotFoundPage = () => {
  return (
    <>
      <SEO title={"Page Not Found - Tv2u"} />
      <div style={styles.sectionA} className="py-5 px-0 m-0">
        <Row style={styles.sectionA}>
          <Col xs={12} className="text-center">
            <img
              src={img_404}
              alt="404_error"
              placeholder="blurred"
              loading="lazy"
              style={{ maxHeight: 379 }}
            />

            <h1 className="pt-5" style={{ fontSize: "1.5rem" }}>
              Oops! The page you are looking for does not seem to exist.
            </h1>
            <p>But don’t worry, it can happen to the best of us.</p>
            <p className="pt-5">Looking for anything specific?</p>
            <Link to="/">
              <button className="btn_black_border">
                Back to the Home Page We Go
              </button>
            </Link>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default NotFoundPage;
